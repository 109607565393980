import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import { BaseLayout } from '../components'

export default function Page() {
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'ja' }}>
        <title>お探しのページは見つかりませんでした</title>
        <meta name="og:title" content="お探しのページは見つかりませんでした" />
      </Helmet>
      <BaseLayout>
        <p>お探しのページは見つかりませんでした</p>
        <p>アクセスしようとしたページは削除されたか、移動した可能性があります。</p>
        <p>
          <Link to="/">トップページ</Link>から目的の記事を探してみてください。
        </p>
      </BaseLayout>
    </>
  )
}
